<template>
    <div style="background: linear-gradient(226deg, #03DAC6 0%, #5091E6 47.38%, #812CC8 100%);">
        <div>
            <headerComponent></headerComponent>
        </div>

        <div class="aboutGrid">
            <div class="aboutGridRow" style="grid-row: 1" >
                <div class="aboutGridColumn1R" >
                    <h1 style="text-align: left">Become a Monoversian</h1>
                    <div class="aboutGridColumn2Mobile" >
                        <img class="aboutsvg1" src="@/assets/images/about1.svg" style="margin: auto">
                    </div>
                    <p class="aboutList">
                        1. Select a popular .com domain name from our portfolio.<br>
                        2. Gain attention with Monoverse NFT-like digital assets.<br>
                        3. Join Monoverse Community of High-End individuals.<br>
                        4. Invest in a Collectable Monoverse Domain.
                    </p>
                    <a href="/domains"><div class="headerJoinBtnMain" style="width: 228px; height: 48px;margin-bottom: 101px;margin-top: 32px" ><p class="headerMoreBtntext">Explore our domain portfolio</p></div></a>
                </div>
                <div class="aboutGridColumn2" >
                    <img class="aboutsvg1" src="@/assets/images/about1.svg" style="margin: auto">
                </div>
            </div>
            <div class="aboutGridRow" style="grid-row: 2" >
                <div class="aboutGridColumn1L" >
                    <img src="@/assets/images/about2.svg" style="margin: auto">
                </div>
                <div class="aboutGridColumn2Text" >
                    <h1 style="text-align: left">We create digital assets for you</h1>
                    <div class="aboutGridColumn1LMobile" >
                        <img src="@/assets/images/about2.svg" style="margin: auto">
                    </div>
                    <p class="aboutPtext">After you’ve selected your domain you can instantly start your business by using Monoverse’s digital assets.</p>
                    <a href="/contact"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 75px;margin-top: 32px" ><p class="headerMoreBtntext">Request a Sample</p></div></a>

                </div>
            </div>
            <div class="aboutGridRow" style="grid-row: 3" >
                <div class="aboutGridColumn1R" >
                    <h1 style="text-align: left;width: 80%">Community Services Upon Request</h1>
                    <div class="aboutGridColumn2mageMobile" >
                        <img src="@/assets/images/about3.svg" style="margin: auto">
                    </div>
                    <ul class="aboutList">
                        <li class="aboutList2"><p>Tailored branding </p></li>
                        <li class="aboutList2"><p>Website Development</p></li>
                        <li class="aboutList2"><p>Server Infrastructure</p></li>
                        <li class="aboutList2"><p>Fund Raising</p></li>
                        <li class="aboutList2"><p>HR Services</p></li>
                        <li class="aboutList2"><p>Legal & Tax</p></li>
                    </ul>
                    <a href="/contact"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 101px;margin-top: 32px" ><p class="headerMoreBtntext">Contact Us</p></div></a>
                </div>
                <div class="aboutGridColumn2mage" >
                    <img src="@/assets/images/about3.svg" style="margin: auto">
                </div>
            </div>
            <div class="aboutGridRow" style="grid-row: 4" >
                <div class="aboutGridColumn1L" >
                    <img src="@/assets/images/about4.svg" style="margin: auto">
                </div>
                <div class="aboutGridColumn2Text" >
                    <h1 style="text-align: left">Monoverse Domain Parking</h1>
                    <div class="aboutGridColumn1LMobile" >
                        <img src="@/assets/images/about4.svg" style="margin: auto">
                    </div>
                    <p class="aboutPtext">Become a Monoversian by assigning your existing Domain Portfolio to Monoverse.<br>
                    </p>
                    <p class="aboutList3">
                        - Add your own content and tags<br>
                        - Increase the value of your Domains.<br>
                        - Generate advertising revenue
                    </p>
                    <p class="aboutPtext">New features and onboarding will open in 2024.<br></p>
                    <a href="/contact"><div class="headerJoinBtnMain" style="width: 177px; height: 48px;margin-bottom: 65px;margin-top: 32px" ><p class="headerMoreBtntext">Contact Us</p></div></a>
                </div>
            </div>
        </div>

        <div>
            <mainfooter></mainfooter>
        </div>
    </div>
</template>

<script>
    import headerComponent from "@/components/reusable/header";
    import mainfooter from "@/components/footer";

    export default {
        name: "thankYouMail",
        components: {headerComponent,mainfooter},
        data: () => ({
            show:false,
        }),
        mounted() {
            this.show = true; // might need this.$nextTick
        },

    }
</script>

<style>


</style>


